
import _ from 'lodash';

const rules = {
    ROLE_EXPERT: {
        static: [
            "configuration:read",
            "requirement:list",
            "requirement:read",
            "sheet:dashboard",
            "sheet:list",
            "sheet:read",
            "sheet:write",
            "documentaryspace:list",
            "documentaryspace:write",
            "dashboard:search-need-technical",
            "dashboard:search-need-technical-affectation",
            "documentaryspace:write",
            "documentaryspace:list",
        ]
    },
    ROLE_LIBRARIAN: {
        static: [
            "sheet:dashboard",
            "sheet:list",
            "sheet:read",
            "sheet:write",
            "configuration:read",
            "referential:list",
            "referential:read",
            "referential:write",
            "requirement:list",
            "requirement:read",
            "requirement:write",
            "documentaryspace:list",
            "documentaryspace:write",
            "documentaryspace:list",
            "dashboard:search-need-synthesis",
            "dashboard:search-need-consolidation",
            "dispatch:write",
            "dispatch:list",
            "legifrance:list",
            "affectations:list",
            "obligationgenerator:read",
            "obligationgenerator:write",
        ]
    },
    ROLE_CONSULTANT: {
        static: [
            "account:list",
            "account:read",
            "externaluser:list",
            "configuration:read",
            "requirement:list",
            "requirement:read",
            "sheet:list",
            "sheet:read",
            "sheet:private:create",
            "sheet:affect",
            "maillog:list",
            "push:list",
            "hotline:list",
            "hotline:write",
            "sheetaffected:list",
            "conformityquizz:write",
            "affectations:list",
            "obligationgenerator:read",
            "obligationgenerator:write",
        ],
        dynamic: {
            "account:write": (user, data) => {
                if (_.isNil(data.id)) return true;
                let canWrite = data.is_start ? true : false;
                data.pilot_consultants.forEach(u => {
                    canWrite = canWrite || (user.id === u || user.id === u.id);
                });
                data.associate_consultants.forEach(u => {
                    canWrite = canWrite || (user.id === u || user.id === u.id);
                });
                return canWrite;
            },
            "externaluser:read": (user, data) => {
                if (_.isNil(data.account.id)) return true;
                let canWrite = data.account.is_start ? true : false;
                data.account.pilot_consultants.forEach(u => {
                    canWrite = canWrite || (user.id === u || user.id === u.id);
                });
                data.account.associate_consultants.forEach(u => {
                    canWrite = canWrite || (user.id === u || user.id === u.id);
                });
                return canWrite;
            },
            "externaluser:write": (user, data) => {
                if (_.isNil(data.account.id)) return true;
                let canWrite = data.account.is_start ? true : false;
                data.account.pilot_consultants.forEach(u => {
                    canWrite = canWrite || (user.id === u || user.id === u.id);
                });
                data.account.associate_consultants.forEach(u => {
                    canWrite = canWrite || (user.id === u || user.id === u.id);
                });
                return canWrite;                
            },
            "sheet:private:write": (user, data) => {
                let canWrite = false;
                data.account.pilot_consultants.forEach(u => {
                    canWrite = canWrite || (user.id === u || user.id === u.id);
                });
                data.account.associate_consultants.forEach(u => {
                    canWrite = canWrite || (user.id === u || user.id === u.id);
                });
                return canWrite;
            }
        }
    },
    ROLE_ADMIN: {
        static: [
            "account:list",
            "account:read",
            "account:write",
            "account:export",
            "account:export-all-watchsites",
            "externaluser:list",
            "externaluser:read",
            "externaluser:write",
            "internaluser:list",
            "internaluser:read",
            "internaluser:write",
            "referential:list",
            "referential:read",
            "referential:write",
            "configuration:read",
            "configuration:write",
            "requirement:list",
            "requirement:read",
            "requirement:write",
            "sheet:dashboard",
            "sheet:list",
            "sheet:read",
            "sheet:write",
            "sheet:private:create",
            "sheet:private:write",
            "sheet:affect",
            "connectionhistory:list",
            "maillog:list",
            "legifrance:list",
            "push:list",
            "hotline:list",
            "hotline:write",
            "documentaryspace:list",
            "documentaryspace:write",
            "dashboard:search-need-decision",
            "dashboard:search-need-technical-affectation",
            "dashboard:search-need-consolidation",
            "dispatch:write",
            "dispatch:list",
            "sheetaffected:list",
            "conformityquizz:write",
            "affectations:list",
            "obligationgenerator:read",
            "obligationgenerator:write",
        ]
    },
    ROLE_SUPER_ADMIN: {
        static: [
            "account:list",
            "account:read",
            "account:write",
            "account:export",
            "account:export-all-watchsites",
            "externaluser:list",
            "externaluser:read",
            "externaluser:write",
            "internaluser:list",
            "internaluser:read",
            "internaluser:write",
            "referential:list",
            "referential:read",
            "referential:write",
            "configuration:read",
            "configuration:write",
            "requirement:list",
            "requirement:read",
            "requirement:write",
            "sheet:dashboard",
            "sheet:list",
            "sheet:read",
            "sheet:write",
            "sheet:private:create",
            "sheet:private:write",
            "sheet:affect",
            "connectionhistory:list",
            "maillog:list",
            "legifrance:list",
            "push:list",
            "hotline:list",
            "hotline:write",
            "documentaryspace:list",
            "documentaryspace:write",
            "dashboard:search-need-decision",
            "dashboard:search-need-technical-affectation",
            "dashboard:search-need-consolidation",
            "dispatch:write",
            "dispatch:list",
            "sheetaffected:list",
            "conformityquizz:write",
            "affectations:list",
            "obligationgenerator:read",
            "obligationgenerator:write",
        ]
    }
};
  
export default rules;