import { React, useEffect, useState, useContext, Prompt, toast, DefaultLayout, _, Link } from 'commons';
import { FieldComponent, LoadButton, FileComponent, PageLoadingComponent, PreviewFieldComponent } from 'shared/components';
import { useForm, useSecurity } from 'shared/hooks';
import { ReferentialContext } from 'shared/stores';
import { SheetApi, AccountApi } from 'shared/api';
import { REFERENTIAL_TEXT_TYPE, SHEET_ACTION } from 'shared/data';
import { compileErrors, compileDataToSelectOptions, denyIfCantPerform, canPerform } from "shared/services";

export default function SheetPrivateForm(props) {

    if (!props.match.params.id) {
        denyIfCantPerform(props, "sheet:private:create");
    }

    const DEFAULT_TEXT_TYPE_LABEL = "Fiche client";

    const [data, errors, setErrors, setValue, setData, reloadOrClose, formHasModifications] = useForm({
        id: null,
        title: '',
        reglementary_text: null,
        text_number: '',
        publication_date: new Date(),
        text_date: new Date(),
        text_type: null,
        synthesis: '',
        categories: [],
        main_domain: null,
        main_subdomain: null,
        main_theme: null,
        confidential_account: null,
    });
    const [user] = useSecurity();
    const [loading, setLoading] = useState(true);
    const [availableDomains, setAvailableDomains] = useState([]);
    const [availableSubdomains, setAvailableSubdomains] = useState([]);
    const [availableThemes, setAvailableThemes] = useState([]);
    const [confidentialAccount, setConfidentialAccount] = useState(null);
    const [saving, setSaving] = useState("");
    const [referentialContext] = useContext(ReferentialContext);
    const [accounts, setAccounts] = useState([]);
    const domainTree = _.cloneDeep(referentialContext["tree"]);
    const textTypes = compileDataToSelectOptions(referentialContext[REFERENTIAL_TEXT_TYPE], 'id', 'value');

    function load () {
        // -- edition
        if (props.match.params.id) {
            SheetApi
                .get(props.match.params.id)
                .then(response => {
                    const newData = {
                        id: response.id,
                        title: response.title,
                        reglementary_text: response.reglementary_text,
                        text_number: response.text_number,
                        publication_date: null,
                        text_date: null,
                        text_type: response.text_types.length > 0 ? response.text_types[0].id : null,
                        synthesis: response.synthesis,
                        categories: response.categories.map(elem => elem.id),
                        main_domain: response.main_domain ? response.main_domain.id : null,
                        main_subdomain: response.main_subdomain ? response.main_subdomain.id : null,
                        main_theme: response.main_theme ? response.main_theme.id : null,
                        confidential_account: response.confidential_account ? response.confidential_account.id : null,
                    };
                    
                    if (response.publication_date) {
                        newData.publication_date = new Date(response.publication_date);
                    }
                    if (response.text_date) {
                        newData.text_date = new Date(response.text_date);
                    }

                    setData(newData);
                    setConfidentialAccount(response.confidential_account);
                })
                .catch(response => {
                    toast.error("Une erreur est survenue lors de la récupération de la fiche");
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }

        // -- Text type by default
        if (data.text_type === null) {
            textTypes.forEach(textType => {
                if (textType.label === DEFAULT_TEXT_TYPE_LABEL) {
                    setValue("text_type", textType.value);
                }
            })
        }

        if (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_SUPER_ADMIN")) {
            AccountApi
                .search({}, 0, 99999, "name", "ASC")
                .then(([results]) => setAccounts(compileDataToSelectOptions(results)))
                .catch(() => toast.error("Impossible de récupérer vos comptes."));
        } else {
            AccountApi
                .search(
                    {"bool": {"should": [{"match":{"pilot_consultants": user.id}}, {"match":{"associate_consultants": user.id}}]}},
                    0,
                    99999,
                    "name",
                    "ASC"
                )
                .then(([results]) => setAccounts(compileDataToSelectOptions(results)))
                .catch(() => toast.error("Impossible de récupérer vos comptes."));
        }
    }

    function updateMainCategoriesOptions() {
        const newDomains = [];
        const newSubDomains = [];
        const newThemes = [];
        const mapper = (item) => {
            if (data.categories.includes(item.value)) {
                if (item.type === 1) {
                    newDomains.push(item);
                }
                if (item.type === 2) {
                    newSubDomains.push(item);
                }
                if (item.type === 3) {
                    newThemes.push(item);
                }
            }
            if (item.children) {
                item.children = item.children.map(mapper);
            }

            return item;
        }; 

        domainTree.map(mapper);

        setAvailableDomains(newDomains);
        setAvailableSubdomains(newSubDomains);
        setAvailableThemes(newThemes);
    }

    // eslint-disable-next-line
    useEffect(load, []);
    // eslint-disable-next-line
    useEffect(updateMainCategoriesOptions, [data.categories]);

    function submit(event) { 
        event.preventDefault();
        if (event.target.id === "form-private-sheet") {
            setSaving(true);

            const submitButton = event.nativeEvent.submitter.name;
            const actionId = SHEET_ACTION[submitButton];

            let formData = {...data};
            formData.action = actionId;
            formData.reglementary_text = data.reglementary_text ? data.reglementary_text.hash : null;
            formData.text_types = data.text_type ? [data.text_type] : null;

            SheetApi
                .save(formData, true)
                .then(([message, sheet]) => {
                    setData(Object.assign({}, data, {"id": sheet.id}));
                    setErrors({});
                    setSaving(false);
                    toast.success(message);
                    reloadOrClose(event, props, "sheets/private", sheet.id);
                })
                .catch(error => {
                    setSaving(false);

                    const data = error.response.data;

                    if (data.message) {
                        toast.error(data.message);
                    }

                    if(data.errors) {
                        setErrors(compileErrors(data.errors));
                    }
                });
            
            setSaving(submitButton);
        }
    }

    function categoriesChange(values) {
        let newValues = [];

        const mapper = (item) => {
            if (item.children) {
                item.children = item.children.map(mapper);
                item.children.forEach(child => {
                    if (!newValues.includes(item.value)
                        && (values.includes(child.value) || newValues.includes(child.value))
                    ) {
                        newValues.push(item.value);
                    }
                });
            }
            if (values.includes(item.value) && !newValues.includes(item.value)) {
                newValues.push(item.value);  
            }

            return item;
        }; 

        domainTree.map(mapper);

        setValue("categories", newValues);
    }

    if (loading) {
        return <PageLoadingComponent label="Fiche privée" />
    }

    return (
        <DefaultLayout screen="E21" title="Fiche privée">
            <Prompt
                when={formHasModifications()}
                message="Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?"
            />
            <h1>Fiche privée</h1>

            <section className="bloc">
                <form onSubmit={submit} id="form-private-sheet" className="form">
                    <section id="configuration" className="bloc">
                        <header className="bg-gris-45 uppercase">Fiche privative</header>

                        <section className="border-gris-25 border-lrb bg-blanc">
                            <FieldComponent
                                label="Titre"
                                name="title"
                                value={data.title}
                                onChange={value => setValue("title", value)}
                                error={errors.title}
                            />
                            <div className="row">
                                <div className="col-md-6">
                                    <FileComponent
                                        label="Texte réglementaire (PDF)"
                                        extensions={[".pdf"]}
                                        value={data.reglementary_text}
                                        name="reglementary_text"
                                        onChange={value => setValue('reglementary_text', value)}
                                        upload={SheetApi.uploadReglementaryText}
                                        error={errors.reglementary_text}
                                        showSize
                                        maxSize="20MO"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <FieldComponent
                                        label="Numéro de texte"
                                        name="text_number"
                                        className="field small"
                                        value={data.text_number}
                                        error={errors.text_number}
                                        onChange={value => setValue("text_number", value)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <FieldComponent
                                        label="Date de publication"
                                        type="date"
                                        name="publication_date"
                                        className="field small"
                                        value={data.publication_date}
                                        error={errors.publication_date}
                                        onChange={value => setValue("publication_date", value)}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <FieldComponent
                                        label="Date du texte"
                                        type="date"
                                        name="text_date"
                                        className="field small"
                                        value={data.text_date}
                                        error={errors.text_date}
                                        onChange={value => setValue("text_date", value)}
                                    />
                                </div>
                            </div>
                            <FieldComponent
                                label="Synthèse"
                                type="textarea"
                                rte
                                name="synthesis"
                                value={data.synthesis}
                                error={errors.synthesis}
                                onChange={value => setValue("synthesis", value)}
                                className="field h-100px"
                            />
                            <FieldComponent 
                                type="dropdown-tree-select"
                                label="Domaines / Sous domaines / Thèmes *"
                                name="categories"
                                data={domainTree}
                                error={errors.categories}
                                mode="hierarchical"
                                value={data.categories}
                                onChange={categoriesChange}
                            />
                            <div className="row">
                                <div className="col-md-4">
                                    <FieldComponent
                                        label="Domaine principal"
                                        name="main_domain"
                                        className="field small"
                                        value={data.main_domain}
                                        type="select"
                                        options={availableDomains}
                                        error={errors.main_domain}
                                        onChange={value => setValue("main_domain", value)}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FieldComponent
                                        label="Sous-domaine principal"
                                        name="main_subdomain"
                                        className="field small"
                                        value={data.main_subdomain}
                                        type="select"
                                        options={availableSubdomains}
                                        error={errors.main_subdomain}
                                        onChange={value => setValue("main_subdomain", value)}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <FieldComponent
                                        label="Thème principal"
                                        name="main_theme"
                                        className="field small"
                                        value={data.main_theme}
                                        type="select"
                                        options={availableThemes}
                                        error={errors.main_theme}
                                        onChange={value => setValue("main_theme", value)}
                                    />
                                </div>
                            </div>
                            {confidentialAccount === null && <FieldComponent
                                label="Compte"
                                type="select"
                                name="confidential_account"
                                value={data.confidential_account}
                                error={errors.confidential_account}
                                onChange={value => setValue("confidential_account", value)}
                                options={accounts}
                                clearable
                            />}
                            {confidentialAccount !== null && <PreviewFieldComponent label="Compte">{confidentialAccount.name}</PreviewFieldComponent>}                            
                            <FieldComponent
                                label="Type de texte"
                                type="select"
                                name="text_type"
                                options={textTypes}
                                value={data.text_type}
                                clearable
                                error={errors.text_type}
                                onChange={value => setValue("text_type", value)}
                            />                        
                        </section>
                    </section>
                    {!props.match.params.id && <section className="row">
                        <div className="col-md-12 text-right">
                            <LoadButton 
                                loading={saving} 
                                label="Créer la fiche et l'ajouter à mon push"
                                name="save-and-add-to-my-push"
                                id="save-and-add-to-my-push"
                            />
                            <LoadButton 
                                loading={saving} 
                                label="Créer la fiche et l'ajouter au push du compte"
                                name="save-and-add-to-account-push"
                                id="save-and-add-to-account-push"
                            />
                        </div>
                    </section>}
                    {props.match.params.id && <section className="row">
                        <div className="col-md-12 text-right">
                            {canPerform("sheet:private:write", {account: confidentialAccount}) && <LoadButton 
                                loading={saving} 
                                label="Enregistrer"
                                name="save-sheet"
                                id="save"
                            />}
                            {canPerform('requirement:list') && <Link
                                id="goto-requirements"
                                to={`/sheets/${props.match.params.id}/requirements/`}
                                className="btn btn-primary"
                                target="_blank"
                            >
                                <i className="icon-boutons-lien-vers" aria-hidden="true"></i>
                                Accéder aux exigences
                            </Link>}
                        </div>
                    </section>}
                </form>
            </section>
        </DefaultLayout>
    )
}