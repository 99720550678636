import ApiRequest from 'shared/services/ApiRequest';

const AbstractApi = {

    save: function(endpoint, data, idField = "id") {
        let method = "POST";

        if (data[idField] && data[idField] > 0) {
            method = "PUT";
            endpoint += "/" + data[idField];
        }

        return ApiRequest.request({
            method: method,
            url: endpoint,
            data: data,
        }).then(response => response.data);
    },

    get: function(endpoint, id) {
        return ApiRequest.get(`${endpoint}/${id}`).then(response => response.data);
    },

    generateSearchBody: function(query, from, size, sorting, direction) {
        const sort = [];
        if (sorting) {
            sorting.split("+").forEach(item => {
                sort.push({[item]: direction});
            });
        }

        return {
            "query": query,
            "from": from,
            "size": size,
            "sort": sort
        };
    },
    
    search: function(endpoint, query, from, size, sorting, direction) {
        return ApiRequest
            .post(
                endpoint,
                this.generateSearchBody(query, from, size, sorting, direction)
            ).then(response => [
                response.data.results, 
                response.data.count,
                response.data.total
            ]);
    },

    list: function(endpoint, params = {}) {
        return ApiRequest
            .request({
                method: "GET",
                url: endpoint,
                params: params,
            })
            .then(response => [
                response.data.results, 
                response.data.count,
                response.data.total
            ]);
    },

    count: function(endpoint, query) {
        return ApiRequest
            .post(
                endpoint,
                {
                    "query": query
                }
            ).then(response => response.data.total);
    },

    delete: function(endpoint, id) {
        return ApiRequest.delete(`${endpoint}/${id}`);
    },

    export: function(endpoint, query, mode = "", fileName = "", sorting = null, direction = null) {
        let params = {"query": query};
        if (fileName !== "") {
            params.filename = fileName;
        }
        if (sorting !== null && direction !== null) {
            params.sort = [];
            params.sort.push({[sorting]: direction});
        }

        return ApiRequest
            .post(
                `${endpoint}/exports` + (mode ? `/${mode}` : ""),
                params,
                {responseType: 'blob'}
            )
            .then(response => response.data);
    }
}
export default AbstractApi;